import React, { useState } from 'react';
import GobblerGrabber from './components/Builder/GobblerGrabber';
import './TWViews.css';

const TWViews = () => {
  const [followers, setFollowers] = useState([]);
  const [loading, setLoading] = useState(true); // State to manage loading animation
  const [searchQuery, setSearchQuery] = useState(''); // State to manage search query

  // Callback to receive data from GobblerGrabber
  const handleDataReceived = (data) => {
    if (data && data.getTwChannelStats && data.getTwChannelStats.channelFollowers) {
      // Flatten the array to get the followers data directly
      const flattenedFollowers = data.getTwChannelStats.channelFollowers.map(f => f.followers);
      setFollowers(flattenedFollowers);
      setLoading(false); // Turn off loading animation when data is received
    }
  };

  // Function to format date as "Abbreviated month day, year"
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  // Function to handle search query changes
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filtered followers based on search query
  const filteredFollowers = followers.filter((follower) =>
    follower.user_login.toLowerCase().startsWith(searchQuery.toLowerCase())
  );

  return (
    <div className="container">
      <h1>Twitch Channel Followers</h1>
      <input
        type="text"
        placeholder="Search followers..."
        value={searchQuery}
        onChange={handleSearchChange}
        className="search-bar"
      />
      <GobblerGrabber onDataReceived={handleDataReceived} />

      {loading ? (
        <p className="loading"></p>
      ) : (
        <ul>
          {filteredFollowers.map((follower, index) => (
            <li key={index}>
              <div className="flip-card">
                <div className="flip-card-front">
                  <strong>{follower.user_login}</strong>
                </div>
                <div className="flip-card-back">
                  {formatDate(follower.followed_at)}
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TWViews;
