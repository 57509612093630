import React from 'react';
import Sidebar from './components/SideBar';
import DefaultHeader from './components/DefaultHeader';
import TWViews from './TWViews';
import "./TwitchPage.css";
import TWMonthlyGrowth from './TWMonthlyGrowth';
import TWMods from './TWMods';
import TWBan from './TWBan';

const TwitchPage = () => {
    return (
      <div className="twitch-page">
        <DefaultHeader />
        <div className="page-content">
          <Sidebar />
          <div className="twitch-content">
            <TWViews/>
            <TWMods/>

            <TWMonthlyGrowth/>
            <TWBan/>
            
          </div>
        </div>
      </div>
    );
  };

export default TwitchPage;
