import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import './TwitchViews.css';

const TwitchViews = () => {
  const [totalViews, setTotalViews] = useState(null); // Changed initial state to null
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('https://api.channalytics.live/api/twitch/vods', {
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const jsonData = await response.json();
      console.log("Fetched data:", jsonData);

      // Assuming jsonData is an array containing inner arrays for each stream
      const combinedData = jsonData.flat(); // Flatten the array to access individual video/stream objects

      // Calculate total views
      const total = combinedData.reduce((acc, stream) => {
        const viewCount = parseInt(stream.view_count); // Parse view_count as an integer
        return isNaN(viewCount) ? acc : acc + viewCount; // Check if viewCount is NaN before adding
      }, 0);
      setTotalViews(total);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error);
    }
  };

  return (
    <div className="Twitch-Views">
      <div className="header-Twitch-Views">Total Twitch Views</div>
      <div className="icon-and-views">
        <FontAwesomeIcon icon={faEye} color="#e06805" size="2x" />
        {totalViews !== null && <span className="view-count">{totalViews}</span>}
      </div>
      {error && <div>Error: {error.message}</div>}
    </div>
  );
};

export default TwitchViews;
