import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import TwitchConnect from './TwitchConnect';
import { useClerk } from "@clerk/clerk-react";
import TwitchViews from './Widgets/TwitchViews';
import './Header.css';
import TwitchFollowers from './Widgets/TwitchFollowers';
import YoutubeAuth from './YoutubeConnect'; // Import YoutubeAuth component
import AddComponent from './Builder/AddComponent';

const Header = ({ data, onComponentAdded, onFilterApply }) => {
  const handleComponentAdded = (component) => {
    onComponentAdded(component);
  };
  const { signOut } = useClerk();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleSignOut = () => {
    signOut().then(() => {
      console.log('User signed out successfully');
    }).catch(error => {
      console.error('Error signing out:', error);
    });
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="header">
      {/* TwitchViews component */}
      <TwitchViews />
      <TwitchFollowers />
      <AddComponent
        onComponentAdded={handleComponentAdded}
        data={data}
        onFilterApply={onFilterApply} // Pass the onFilterApply callback directly
      />
      {/* Profile Button */}
      <div className="profile-button" onClick={toggleDropdown}>
        <FontAwesomeIcon icon={faUser} />
      </div>
      {/* Dropdown menu */}
      <div className={`header-dropdown ${isDropdownOpen ? 'show' : ''}`}>
        <div className='header-dropdown-items'>
          <TwitchConnect />
          <YoutubeAuth />
        </div>
        <button onClick={handleSignOut}>Sign Out</button>
      </div>
    </div>
  );
};

export default Header;
