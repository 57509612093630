import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import Draggable from 'react-draggable';
import './SubChart.css';

const SubChart = ({ gridX, gridY, bounds }) => {
  const [followerData, setFollowerData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Default to current year
  const [monthlySubscribers, setMonthlySubscribers] = useState({});
  const totalYears = 4; // Total number of years to show in the carousel
  const [startRange, setStartRange] = useState(selectedYear - Math.floor(totalYears / 2));
  const [isArrowClicked, setIsArrowClicked] = useState(false); // State to track arrow button clicks

  useEffect(() => {
    fetchData();
  }, [selectedYear]);

  const fetchData = async () => {
    try {
      const response = await fetch(`https://api.channalytics.live/api/twitch/followers?year=${selectedYear}`, {
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const jsonData = await response.json();
      console.log("API Response:", jsonData);

      if (jsonData && jsonData.data) {
        setFollowerData(jsonData.data);
        countMonthlySubscribers(jsonData.data);
      } else {
        throw new Error('Invalid API response format or empty data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error);
    }
  };

  const countMonthlySubscribers = (data) => {
    const filteredData = data.filter(follower => {
      const year = new Date(follower.followed_at).getFullYear();
      return year === selectedYear;
    });
  
    const monthlyCounts = filteredData.reduce((acc, follower) => {
      const monthYear = new Date(follower.followed_at).toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
      if (acc[monthYear]) {
        acc[monthYear]++;
      } else {
        acc[monthYear] = 1;
      }
      return acc;
    }, {});
    setMonthlySubscribers(monthlyCounts);
  };

  const handleYearChange = (year) => {
    // Update the selected year
    setSelectedYear(year);
    // Set arrow clicked state to true
    setIsArrowClicked(true);
  };

  const handleCarouselLeft = () => {
    // Move the year range to the left by 1 year
    if (startRange > 2011) {
      setStartRange(startRange - 1);
      // Set arrow clicked state to true
      setIsArrowClicked(true);
    }
  };

  const handleCarouselRight = () => {
    // Move the year range to the right by 1 year
    if (startRange + totalYears <= 2050) {
      setStartRange(startRange + 1);
      // Set arrow clicked state to true
      setIsArrowClicked(true);
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Prepare data for the pie chart
  const chartData = {
    labels: Object.keys(monthlySubscribers),
    datasets: [
      {
        label: 'Subscribers by Month',
        data: Object.values(monthlySubscribers),
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(75, 192, 192, 0.6)',
          'rgba(153, 102, 255, 0.6)',
          'rgba(255, 159, 64, 0.6)',
        ],
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
    },
    width: 200,
    height: 200,
  };

  return (
    <Draggable grid={[gridX, gridY]} bounds={bounds}>
    <div className="sub-chart-container">
      <div className="sub-chart-header">
        <h2 className="sub-chart-title">Twitch Followers By Month</h2>
      </div>
      <div className="sub-chart-footer">
        <div className="arrow-buttons-container">
          <button className="button" onClick={handleCarouselLeft}>&lt;</button> {/* Left arrow */}
        </div>
        <div className="year-buttons-container">
          {[...Array(totalYears)].map((_, index) => {
            const year = startRange + index;
            return (
              <button 
                key={year}
                className={`button ${selectedYear === year ? 'active' : ''}`}
                onClick={() => handleYearChange(year)}
              >
                {year}
              </button>
            );
          })}
        </div>
        <div className="arrow-buttons-container">
          <button className="button" onClick={handleCarouselRight}>&gt;</button> {/* Right arrow */}
        </div>
      </div>
      <div className="sub-chart-box">
        <Pie data={chartData} options={chartOptions} />
      </div>
    </div>
  </Draggable>
);
};

export default SubChart;
