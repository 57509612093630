import React from 'react';
import adminPhoto from './Photo1.gif'; // Import your photo
import './Admin.css'; // Import your CSS file for styling

const Admin = () => {
  return (
    <div className="admin-container">
      <img src={adminPhoto} alt="Admin" className="admin-image" />
    </div>
  );
};

export default Admin;
