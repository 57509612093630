import React, { useEffect } from "react";
import { SignedOut, SignedIn, SignInButton, useUser } from "@clerk/clerk-react";
import App from "./App";


function Login() {
    const { user } = useUser();

    useEffect(() => {
        if (user) {
            localStorage.setItem('clerkUserId', user.id); 
            
            fetch('https://api.channalytics.live/api/session', {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ clerkUserId: user.id }),
                credentials: 'include'
            })
            .then(response => {
                // Handle response
            })
            .catch(error => {
                // Handle error
            });
        }
    }, [user]);

    return (
        <div>
            <SignedOut>
                <h2>Login</h2>
                <SignInButton />
            </SignedOut>
            <SignedIn>
                <App />
            </SignedIn>
        </div>
    );
}

export default Login;
