import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCalendar, faPieChart } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './HomeSidebar.css';

const HomeSidebar = () => {
  return (
    <div className="home-sidebar">
      {/* Link to Home component */}
      <Link to="/home" className="orange-icon large-icon">
        <FontAwesomeIcon icon={faHome} onClick={() => console.log('Home icon clicked')} />
      </Link>
      {/* Link to Calendar component */}
      <Link to="/calendar" className="orange-icon large-icon">
        <FontAwesomeIcon icon={faCalendar} onClick={() => console.log('Calendar icon clicked')} />
      </Link>
      {/* Link to Builder component */}
      <Link to="/builder" className="orange-icon large-icon">
        <FontAwesomeIcon icon={faPieChart} onClick={() => console.log('Pie Chart icon clicked')} />
      </Link>
    </div>
  );
};

export default HomeSidebar;
