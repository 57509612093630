import React, { useState, useEffect } from 'react';
import { Line, Pie, Bar } from 'react-chartjs-2'; // Import necessary chart components
import { format } from 'date-fns';
import './StreamChart.css';
import Draggable from 'react-draggable';

const StreamChart = ({ gridX, gridY, bounds }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [chartType, setChartType] = useState('line'); // State to track chart type

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('https://api.channalytics.live/api/twitch/vods', {
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const jsonData = await response.json();
      console.log("Fetched data:", jsonData);

      const flattenedData = jsonData.flatMap(stream => stream);
      flattenedData.sort((a, b) => new Date(a.published_at) - new Date(b.published_at));

      setData(flattenedData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error);
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Prepare data for the chart based on the selected chart type
  let chartComponent;
  if (chartType === 'line') {
    const chartData = {
      labels: data.map(stream => format(new Date(stream.published_at), 'yyyy-MM-dd')),
      datasets: [
        {
          label: 'Stream Views',
          data: data.map(stream => stream.view_count),
          fill: false,
          borderColor: 'rgba(75, 192, 192, 1)',
          tension: 0.1,
        },
      ],
    };

    chartComponent = <Line data={chartData} />;
  } else if (chartType === 'pie') {
    const chartData = {
      labels: data.map(stream => format(new Date(stream.published_at), 'yyyy-MM-dd')),
      datasets: [
        {
          label: 'Stream Views',
          data: data.map(stream => stream.view_count),
          backgroundColor: [
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
          ],
        },
      ],
    };

    chartComponent = <Pie data={chartData} />;
  } else if (chartType === 'bar') {
    const chartData = {
      labels: data.map(stream => format(new Date(stream.published_at), 'yyyy-MM-dd')),
      datasets: [
        {
          label: 'Stream Views',
          data: data.map(stream => stream.view_count),
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };

    chartComponent = <Bar data={chartData} />;
  }

  const handleChartTypeChange = (type) => {
    setChartType(type);
  };

  return (
    <Draggable grid={[gridX, gridY]} bounds={bounds}>
      <div className="chart-container">
        <h2 className="chart-header">Stream Chart</h2>
        {/* Buttons to switch between chart types */}
        <div className="chart-type-buttons">
          <button className= 'chart-button' onClick={() => handleChartTypeChange('line')}>Line Chart</button>
          <button className= 'chart-button'onClick={() => handleChartTypeChange('pie')}>Pie Chart</button>
          <button className= 'chart-button'onClick={() => handleChartTypeChange('bar')}>Bar Chart</button>
        </div>
        <div className="chart-canvas">
          {chartComponent} {/* Render the selected chart component */}
        </div>
      </div>
    </Draggable>
  );
};

export default StreamChart;
