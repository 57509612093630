import React, { useState } from 'react';
import './AddComponent.css';
import { Line, Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const AddComponent = ({ onComponentAdded, data }) => {
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [selectedDataType, setSelectedDataType] = useState('all');
  const [selectedObject, setSelectedObject] = useState('');
  const [selectedSecondaryObject, setSelectedSecondaryObject] = useState('');
  const [componentName, setComponentName] = useState('');
  const [displayType, setDisplayType] = useState('text'); // New state for display type
  const [chartType, setChartType] = useState('line');

  const handleSelectDataType = (e) => {
    setSelectedDataType(e.target.value);
    setSelectedObject('');
    setSelectedSecondaryObject('');
  };

  const handleSelectObject = (e) => {
    setSelectedObject(e.target.value);
  };

  const handleSelectSecondaryObject = (e) => {
    setSelectedSecondaryObject(e.target.value);
  };

  const handleAddComponent = () => {
    let filteredData = data?.getUserStreamHistory || [];
    if (selectedDataType !== 'all') {
      filteredData = filteredData.filter(item => item.eventType === selectedDataType);
    }

    const newComponentKey = `component-${Date.now()}`;

    if (displayType === 'chart') {
      const labels = [];
      const primaryData = [];
      const secondaryData = [];

      filteredData.forEach(item => {
        if (selectedDataType === 'vod' && Array.isArray(item.eventData)) {
          item.eventData.forEach(vodItem => {
            if (selectedObject) {
              labels.push(vodItem.id);
              primaryData.push(vodItem[selectedObject]);
            }
            if (selectedSecondaryObject) {
              secondaryData.push(vodItem[selectedSecondaryObject]);
            }
          });
        } else if (selectedDataType === 'follower' && item.eventData?.data) {
          item.eventData.data.forEach(follower => {
            if (selectedObject) {
              labels.push(follower.user_id);
              primaryData.push(follower[selectedObject]);
            }
            if (selectedSecondaryObject) {
              secondaryData.push(follower[selectedSecondaryObject]);
            }
          });
        }
      });

      const chartData = {
        labels,
        datasets: [
          {
            label: selectedObject,
            data: primaryData,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderWidth: 1,
          },
          selectedSecondaryObject && {
            label: selectedSecondaryObject,
            data: secondaryData,
            backgroundColor: 'rgba(153,102,255,0.4)',
            borderColor: 'rgba(153,102,255,1)',
            borderWidth: 1,
          },
        ].filter(Boolean),
      };

      const newComponent = (
        <div key={newComponentKey} className="added-component">
          <div className="cc-header">{componentName}</div>
          {renderChart(chartData, chartType)}
        </div>
      );

      onComponentAdded(newComponent);
    } else {
      const newComponent = (
        <div key={newComponentKey} className="added-component">
          <div className="cc-header">{componentName}</div>
          {filteredData.map(item => (
            <div key={item.historyId}>
              {selectedDataType === 'vod' && Array.isArray(item.eventData) && (
                item.eventData.map(vodItem => (
                  <div key={vodItem.id}>
                    {selectedObject && (
                      <p>
                        <strong className="silver-text">{selectedObject}:</strong>{' '}
                        <span className="silver-text">{vodItem[selectedObject]}</span>
                      </p>
                    )}
                    {selectedSecondaryObject && (
                      <p>
                        <strong className="silver-text">{selectedSecondaryObject}:</strong>{' '}
                        <span className="silver-text">{vodItem[selectedSecondaryObject]}</span>
                      </p>
                    )}
                  </div>
                ))
              )}
              {selectedDataType === 'follower' && item.eventData?.data && (
                item.eventData.data.map(follower => (
                  <div key={follower.user_id}>
                    {selectedObject && (
                      <p>
                        <strong className="silver-text">{selectedObject}:</strong>{' '}
                        <span className="silver-text">{follower[selectedObject]}</span>
                      </p>
                    )}
                    {selectedSecondaryObject && (
                      <p>
                        <strong className="silver-text">{selectedSecondaryObject}:</strong>{' '}
                        <span className="silver-text">{follower[selectedSecondaryObject]}</span>
                      </p>
                    )}
                  </div>
                ))
              )}
            </div>
          ))}
        </div>
      );

      onComponentAdded(newComponent);
    }

    setShowFilterMenu(false);
  };

  const getObjectKeys = () => {
    let keys = [];
    data?.getUserStreamHistory?.forEach(item => {
      if (selectedDataType === 'vod' && item.eventType === 'vod') {
        item.eventData?.forEach(vodItem => {
          keys = keys.concat(Object.keys(vodItem));
        });
      } else if (selectedDataType === 'follower' && item.eventType === 'follower') {
        item.eventData?.data?.forEach(obj => {
          keys = keys.concat(Object.keys(obj));
        });
      }
    });
    return Array.from(new Set(keys));
  };

  const renderChart = (data, type) => {
    switch (type) {
      case 'line':
        return <Line data={data} />;
      case 'bar':
        return <Bar data={data} />;
      case 'pie':
        return <Pie data={data} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <button className="addComponent-button" onClick={() => setShowFilterMenu(true)}>
        Add Component
      </button>

      {showFilterMenu && (
        <div className="filter-menu">
          <p>Build Your Component</p>
          <input
            type="text"
            placeholder="Enter component name"
            value={componentName}
            onChange={(e) => setComponentName(e.target.value)}
          />

          <p>Select data type:</p>
          <select value={selectedDataType} onChange={handleSelectDataType}>
            <option value="all">All</option>
            <option value="vod">VOD</option>
            <option value="follower">Follower</option>
          </select>

          {selectedDataType !== 'all' && (
            <>
              <p>Select primary object:</p>
              <select value={selectedObject} onChange={handleSelectObject}>
                <option value="">Select Object</option>
                {getObjectKeys().map(key => (
                  <option key={key} value={key}>{key}</option>
                ))}
              </select>

              <p>Select secondary object:</p>
              <select value={selectedSecondaryObject} onChange={handleSelectSecondaryObject}>
                <option value="">Select Object</option>
                {getObjectKeys().map(key => (
                  <option key={key} value={key}>{key}</option>
                ))}
              </select>
            </>
          )}

          <p>Select display type:</p>
          <select value={displayType} onChange={(e) => setDisplayType(e.target.value)}>
            <option value="text">Text</option>
            <option value="chart">Chart</option>
          </select>

          {displayType === 'chart' && (
            <>
              <p>Select chart type:</p>
              <select value={chartType} onChange={(e) => setChartType(e.target.value)}>
                <option value="line">Line</option>
                <option value="bar">Bar</option>
                <option value="pie">Pie</option>
              </select>
            </>
          )}

          <button onClick={handleAddComponent}>Apply</button>
        </div>
      )}
    </div>
  );
};

export default AddComponent;
