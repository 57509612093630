import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import Sidebar from './components/SideBar';

// Setup the localizer for React Big Calendar using moment.js
const localizer = momentLocalizer(moment);

const events = [
  {
    id: 1,
    title: 'Event 1',
    start: new Date(2024, 5, 10, 10, 0),
    end: new Date(2024, 5, 10, 12, 0),
  },
  // Add more events as needed
];

const CalendarComponent = () => {
  return (
    <div>
      <h1>Calendar</h1>
      <div style={{ height: '500px' }}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
        />
      </div>
      <Sidebar/>
    </div>
  );
};

export default CalendarComponent;
