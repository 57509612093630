import React, { useState, useEffect } from 'react';

const TwitchAuth = () => {
  const CLIENT_ID = 'sjjt8miykodjp4uewerczve9gf80zq';
  const REDIRECT_URI = encodeURIComponent('https://api.channalytics.live/auth/twitch/callback');
  const SCOPES = encodeURIComponent('user:read:email analytics:read:extensions user:edit user:read:blocked_users user:manage:blocked_users channel:read:subscriptions user:read:subscriptions moderation:read moderator:manage:automod moderator:read:automod_settings moderator:manage:automod_settings moderator:manage:banned_users moderator:read:unban_requests moderator:manage:unban_requests moderator:read:blocked_terms moderator:manage:blocked_terms moderator:manage:chat_messages user:read:moderated_channels channel:manage:moderators channel:read:vips channel:manage:vips moderator:manage:shield_mode moderator:read:shield_mode channel:read:polls channel:manage:polls channel:read:predictions channel:manage:predictions channel:manage:raids channel:manage:schedule channel:read:stream_key user:read:follows channel:edit:commercial channel:read:ads channel:manage:ads bits:read channel:manage:broadcast moderator:read:followers clips:edit channel:read:editors');

  const [encodedState, setEncodedState] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const getClerkUserIdFromLocalStorage = () => {
      const clerkUserId = localStorage.getItem('clerkUserId');
      return clerkUserId;
    };

    const clerkUserId = getClerkUserIdFromLocalStorage();
    if (!clerkUserId) {
      setError('Clerk UserID not found in Local Storage');
      return;
    }

    const encodedClerkUserId = encodeURIComponent(clerkUserId);
    const encodedState = btoa(unescape(encodeURIComponent(encodedClerkUserId)));
    setEncodedState(encodedState);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const handleAuth = () => {
    const twitchUrl = `https://id.twitch.tv/oauth2/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPES}&state=${encodedState}`;

    window.open(twitchUrl, '_blank');
  };

  return (
    <div>
      {error && <p>Error: {error}</p>}
      <button onClick={handleAuth}>
        Connect Twitch    
      </button>
    </div>
  );
};

export default TwitchAuth;

