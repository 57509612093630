import React, { useState } from 'react';
import DraggableItem from './DashComponents/DraggableItem';
import TFollowers from './DashComponents/TFollowers';
import SubChart from './DashComponents/SubChart';
import StreamChart from './DashComponents/StreamChart';
import './MainArea.css'; // Make sure to import your MainArea CSS if needed
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const componentsList = [
  { name: 'Followers', component: TFollowers },
  { name: 'SubChart', component: SubChart },
  { name: 'StreamChart', component: StreamChart },
];

const MainArea = ({ dynamicComponents, filteredData }) => {
  const [showItems, setShowItems] = useState({
    showDropdown: false, // Initially the dropdown menu is hidden
  });

  const toggleComponent = (componentName) => {
    setShowItems((prevState) => ({
      ...prevState,
      [componentName]: !prevState[componentName],
    }));
  };

  return (
    <div className="main-area">
      {/* Dropdown header icon */}
      <div className="dropdown-header" onClick={() => toggleComponent('showDropdown')}>
        <FontAwesomeIcon icon={faChevronDown} color="#e06805" size="2x" />
      </div>

      {/* Dropdown menu */}
      {showItems.showDropdown && (
        <div className="dropdown-menu">
          {componentsList.map(({ name }) => (
            <label key={name}>
              <input
                type="checkbox"
                checked={showItems[`show${name}`]}
                onChange={() => toggleComponent(`show${name}`)}
              />
              {name}
            </label>
          ))}
        </div>
      )}

      {/* Render components based on showItems state */}
      <div className="draggable-area">
        {/* Render custom components dynamically */}
        {dynamicComponents.map((component, index) => (
          <div key={`dynamic-component-${index + 1}`}>
            {component}
          </div>
        ))}

        {/* Existing components */}
        {componentsList.map(({ name, component: Component }) => (
          showItems[`show${name}`] && <Component key={name} gridX={50} gridY={50} bounds=".draggable-area" />
        ))}
      </div>

      {/* Render filtered data */}
      {filteredData && (
  <div className="filtered-data">
    {/* Display filtered data */}
    {filteredData.map((item, index) => (
      <div key={index}>
        <p>Title: {item.title}</p>
        <p>Description: {item.description}</p>
        {/* Add more data fields as needed */}
      </div>
    ))}
  </div>
)}
    </div>
  );
};

export default MainArea;
