import React, { useEffect, useState } from 'react';
import './Home.css';
import HomeSidebar from './HomeSidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitch, faYoutube } from '@fortawesome/free-brands-svg-icons'; // Import YouTube icon
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useClerk } from '@clerk/clerk-react'; // Import useClerk from Clerk SDK
import YoutubeAuth from './components/YoutubeConnect';
import TwitchConnect from './components/TwitchConnect';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom



const Home = () => {
  const { signOut } = useClerk();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [followerCount, setFollowerCount] = useState(null);
  const [error, setError] = useState(null);
  const youtubeSubscriberCount = 1000; // Remove later; this is temp data.
  

  useEffect(() => {
    const fetchData = async () => {
      const getClerkUserIdFromLocalStorage = () => {
        const clerkUserId = localStorage.getItem('clerkUserId');
        console.log('Clerk UserID from Local Storage:', clerkUserId);
        return clerkUserId;
      };

      const clerkUserId = getClerkUserIdFromLocalStorage();
      if (!clerkUserId) {
        setError('Clerk UserID not found in Local Storage');
        return;
      }

      console.log('Fetching data...');
      try {
        const response = await fetch('https://api.channalytics.live/api/graphql', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'Clerkuserid': clerkUserId,
          },
          body: JSON.stringify({
            query: `
            query GetUserStreamHistory($clerkUserId: ID!) {
                getUserStreamHistory(clerkUserId: $clerkUserId) {
                  historyId
                  streamId
                  eventType
                  eventData
                  createdAt
                }
              }
            `,
            variables: {
              clerkUserId: clerkUserId,
            }
          }),
        });

        const data = await response.json();
        console.log('API Response:', data);

        // Extract the follower count from the API response
        const followerCount = data?.data?.getUserStreamHistory[1]?.eventData?.total ?? 0;
        setFollowerCount(followerCount);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching data');
      }
    };

    fetchData(); // Call fetchData when component mounts
  }, []); // Empty dependency array ensures useEffect runs only once

  const handleSignOut = () => {
    signOut().then(() => {
      console.log('User signed out successfully');
    }).catch(error => {
      console.error('Error signing out:', error);
    });
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="home-container">
      <HomeSidebar />
      
      <div className="dashboard-content">
        {/* Twitch Button */}
        <Link to="/twitch" className="twitch-button">
          <FontAwesomeIcon icon={faTwitch} className="twitch-icon" />
          <span className="follower-count">{followerCount !== null ? `${followerCount}` : 'Loading...'}</span>
          <span className="follower-text">Followers</span>
        </Link>

        {/* YouTube Button */}
        <Link to="/youtube" className="youtube-button">
          <FontAwesomeIcon icon={faYoutube} className="youtube-icon" />
          <span className="subscriber-count">{youtubeSubscriberCount !== null ? `${youtubeSubscriberCount}` : 'Loading...'}</span>
          <span className="subscriber-text">Subscribers</span>
        </Link>

        {/* Info Box */}
        <div className="info-box">
          <button className="profile-button" onClick={toggleDropdown}>
            <FontAwesomeIcon icon={faUser} className="profile-icon" />
          </button>
          <div className={`dropdown ${isDropdownOpen ? 'show' : ''}`}>
            <div className="dropdown-items">
              <TwitchConnect />
              <YoutubeAuth />
              <button onClick={handleSignOut}>Sign Out</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
