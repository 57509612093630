import React, { useState, useEffect } from 'react';

const YoutubeAuth = () => {
  const CLIENT_ID = '218139722989-5l2otl7rlakmaag1fj3shcascq2ncooj.apps.googleusercontent.com';
  const REDIRECT_URI = encodeURIComponent('https://api.channalytics.live/auth/youtube/callback');
  const SCOPES = encodeURIComponent([
    'https://www.googleapis.com/auth/yt-analytics.readonly',
    'https://www.googleapis.com/auth/youtube',
    'https://www.googleapis.com/auth/youtube.force-ssl',
    'https://www.googleapis.com/auth/youtube.upload',
    'https://www.googleapis.com/auth/youtube.channel-memberships.creator',
    'https://www.googleapis.com/auth/youtubepartner',
    'https://www.googleapis.com/auth/youtubepartner-channel-audit'

  ].join(' '));

  const [encodedState, setEncodedState] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const getClerkUserIdFromLocalStorage = () => {
      const clerkUserId = localStorage.getItem('clerkUserId');
      return clerkUserId;
    };

    const clerkUserId = getClerkUserIdFromLocalStorage();
    if (!clerkUserId) {
      setError('Clerk UserID not found in Local Storage');
      return;
    }

    const encodedClerkUserId = encodeURIComponent(clerkUserId);
    const encodedStateValue = btoa(unescape(encodeURIComponent(encodedClerkUserId)));
    setEncodedState(encodedStateValue);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const handleAuth = () => {
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPES}&access_type=offline&include_granted_scopes=true&state=${encodedState}`;

    window.location.href = authUrl;
  };

  return (
    <div>
      {error && <p>Error: {error}</p>}
      <button onClick={handleAuth}>
        Connect YouTube
      </button>
    </div>
  );
};

export default YoutubeAuth;
