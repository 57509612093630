import React, { useState } from 'react';
import Header from './components/Header.js';
import Sidebar from './components/SideBar.js';
import MainArea from './components/MainArea.js';
import backgroundImage from './Background4.png';
import './App.css'
import GobblerGrabber from './components/Builder/GobblerGrabber.js';

function Builder() {
  const [dynamicComponents, setDynamicComponents] = useState([]);
  const [dataFromGobbler, setDataFromGobbler] = useState(null); // State to hold data from GobblerGrabber
  const [filteredData, setFilteredData] = useState(null); // State to hold filtered data
  

  const handleComponentAdded = (component) => {
    setDynamicComponents(prevComponents => [...prevComponents, component]);
  };

  const handleDataFromGobblerGrabber = (data) => {
    console.log('Data from GobblerGrabber:', data);
    setDataFromGobbler(data); // Set the data received from GobblerGrabber
  };

  const handleFilterApply = (filteredData) => {
    setFilteredData(filteredData); // Set the filtered data received from Header
  };

  return (
    <div className="app">
      {/* Pass onDataReceived callback to GobblerGrabber */}
      <Header onComponentAdded={handleComponentAdded} data={dataFromGobbler} onFilterApply={handleFilterApply} />
      <div className="content">
        <Sidebar />
        {/* Pass filteredData to MainArea */}
        <MainArea dynamicComponents={dynamicComponents} filteredData={filteredData} />
      </div>
      {/* Pass onDataReceived callback to GobblerGrabber */}
      <GobblerGrabber onDataReceived={handleDataFromGobblerGrabber} />
      {/* You can add other components here */}
    </div>
  );
}

export default Builder;
