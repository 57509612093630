import React, { useState, useEffect } from 'react';
import './TWBan.css'; // Add your CSS file here

const TWBan = () => {
  const [bannedUsers, setBannedUsers] = useState([]);

  useEffect(() => {
    // Fetch the banned users data here (you can replace this with your data fetching logic)
    const fetchData = async () => {
      try {
        // Example fetch call - replace with your actual API call
        const response = await fetch('/api/channelBannedUsers');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        // Ensure the data contains banned users
        setBannedUsers(data.channelBannedUsers || []);
      } catch (error) {
        console.error('Failed to fetch banned users:', error);
        setBannedUsers([]); // Set to an empty array in case of an error
      }
    };

    fetchData();
  }, []);

  return (
    <div className="ban-container">
      <h1 className="ban-heading">Banned Users</h1>
      {bannedUsers.length > 0 ? (
        <ul className="ban-list">
          {bannedUsers.map((ban, index) => (
            <li key={index} className="ban-list-item">
              <div className="ban-flip-card">
                <div className="ban-flip-card-inner">
                  <div className="ban-flip-card-front">
                    <strong>{ban.banned_users.user_name}</strong>
                    <p>Reason: {ban.banned_users.reason}</p>
                  </div>
                  <div className="ban-flip-card-back">
                    <p>Expires At: {new Date(ban.banned_users.expires_at).toLocaleDateString()}</p>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="ban-loading">Yay! No one is banned.</p>
      )}
    </div>
  );
};

export default TWBan;
