import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import GobblerGrabber from './components/Builder/GobblerGrabber';
import './TWMonthlyGrowth.css';

const MonthlyFollowerGrowth = () => {
  const [monthlyGrowth, setMonthlyGrowth] = useState(Array(12).fill(0));

  // Callback to receive data from GobblerGrabber
  const handleDataReceived = (data) => {
    if (data && data.getTwChannelStats && data.getTwChannelStats.channelFollowers) {
      const followers = data.getTwChannelStats.channelFollowers;
      const growthData = calculateMonthlyGrowth(followers);
      setMonthlyGrowth(growthData);
    }
  };

  // Function to calculate monthly follower growth
  const calculateMonthlyGrowth = (followers) => {
    const growth = Array(12).fill(0);
    followers.forEach(followerData => {
      const follower = followerData.followers;
      const date = new Date(follower.followed_at);
      const month = date.getMonth(); // 0 for Jan, 1 for Feb, etc.
      growth[month]++;
    });
    return growth;
  };

  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Follower Growth',
        data: monthlyGrowth,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        borderColor: 'rgba(255, 255, 255, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="monthly-growth-container">
      <h1 className="monthly-growth-heading">Cumulative Monthly Follower Growth</h1>
      <GobblerGrabber onDataReceived={handleDataReceived} />
      <div className="monthly-growth-chart-container">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default MonthlyFollowerGrowth;
