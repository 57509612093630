import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home'; // Import your Home component
import Builder from './Builder'; // Import your Builder component
import Admin from './Admin';
import CalendarComponent from './CalendarComponent';
import TwitchPage from './TwitchPage';


function App() {
  useEffect(() => {
    // Navigate to the Home component when the App component mounts
    window.history.pushState({}, '', '/home');
  }, []); // Depend on an empty array to ensure useEffect runs only once

  return (
    <Router>
      <div className="App">
        {/* Routes */}
        <Routes>
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/builder" element={<Builder />} />
          <Route exact path="/Calendar" element={<CalendarComponent />} />
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/Twitch" element={<TwitchPage />} />
          {/* Add more routes as needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
