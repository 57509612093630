import React, { useState, useEffect } from 'react';

const GobblerGrabber = ({ onDataReceived }) => {
  const [error, setError] = useState('');
  const [data, setData] = useState(null);
  const [eventTypeFirstObject, setEventTypeFirstObject] = useState(''); // State for storing eventType of the first object

  useEffect(() => {
    const fetchData = async () => {
      const getClerkUserIdFromLocalStorage = () => {
        const clerkUserId = localStorage.getItem('clerkUserId');
        console.log('Clerk UserID from Local Storage:', clerkUserId);
        return clerkUserId;
      };

      const clerkUserId = getClerkUserIdFromLocalStorage();
      if (!clerkUserId) {
        setError('Clerk UserID not found in Local Storage');
        return;
      }

      console.log('Fetching data...');
      try {
        const response = await fetch('https://api.channalytics.live/api/graphql', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'Clerkuserid': clerkUserId,
          },
          body: JSON.stringify({
            query: `
            query GetTwAndYtData($clerkUserId: ID!) {
              getTwUsers(clerkUserId: $clerkUserId) {
                clerkUserId
                broadcasterName
              }
              getTwChannelStats(clerkUserId: $clerkUserId) {
                clerkUserId
                channelFollowers
                channelSubs
                channelStreams
              }
              getTwChannelDetails(clerkUserId: $clerkUserId) {
                clerkUserId
                channelInformation
                channelEditors
                channelTeam
                channelStreamSchedule
                channelClips
                channelAdSchedule
              }
              getTwModerationDetails(clerkUserId: $clerkUserId) {
                clerkUserId
                channelUserBlockList
                channelModerators
                channelModeratedChannels
                channelBlockedTerms
                channelUnbanRequests
                channelVips
                channelBannedUsers
              }
              getYtData(clerkUserId: $clerkUserId) {
                clerkUserId
                channelData
                commentsData
                playlistsData
              }
            }
            `,
            variables: {
              clerkUserId: clerkUserId,
            }
          }),
        });

        const responseData = await response.json();
        console.log('API response:', responseData);
        setData(responseData.data);

        // Call the onDataReceived callback to send data back to the parent component
        onDataReceived(responseData.data);
      } catch (error) {
        setError('Error fetching data');
        console.error('API error:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run the effect only once

  useEffect(() => {
    // Check if data is not null before accessing it and handle each data type
    if (data) {
      if (data.getTwUsers) {
        // Example of setting event type from getTwUsers data
        setEventTypeFirstObject(data.getTwUsers.broadcasterName || '');
      }

      // Add other data type handling here
      if (data.getTwChannelStats) {
        // Handle TwChannelStats data
        console.log('TwChannelStats:', data.getTwChannelStats);
      }

      if (data.getTwChannelDetails) {
        // Handle TwChannelDetails data
        console.log('TwChannelDetails:', data.getTwChannelDetails);
      }

      if (data.getTwModerationDetails) {
        // Handle TwModerationDetails data
        console.log('TwModerationDetails:', data.getTwModerationDetails);
      }

      if (data.getYtData) {
        // Handle YtData
        console.log('YtData:', data.getYtData);
      }
    }
  }, [data]);

  return (
    <div>
      {error && <p>{error}</p>}
      {/* Render your data or any other UI elements here */}
    </div>
  );
};

export default GobblerGrabber;
