import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import Draggable from 'react-draggable'; // Import Draggable from react-draggable
import './TFollowers.css';

const TFollowers = ({ gridX, gridY, bounds }) => {
  const [followerData, setFollowerData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('https://api.channalytics.live/api/twitch/followers', {
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Loading...');
      }
      const jsonData = await response.json();
      console.log("API Response:", jsonData); // Log the entire response
      if (jsonData && jsonData.data) {
        const formattedData = formatFollowerData(jsonData.data);
        setFollowerData(formattedData); // Update state with formatted follower data
      } else {
        throw new Error('Invalid API response format or empty data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error);
    }
  };

  const formatFollowerData = (data) => {
    return data.map(follower => {
      const formattedDate = format(new Date(follower.followed_at), 'EEEE, MMMM d, yyyy'); // Format date
      return { ...follower, formattedDate };
    });
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Draggable grid={[gridX, gridY]} bounds={bounds}>
      <div className="dashboard-wrapper"> {/* Add a wrapper for the dashboard */}
        <div className="dashboard-header">Twitch Followers</div> {/* Add a header */}
        <ul>
          {followerData.map((follower, index) => (
            <li key={index}>
              <span className="follower-label"></span> {follower.user_login}<br /> {/* Add "Follower: " in front of the username */}
              <span className="followed-date">{follower.formattedDate}</span> {/* Display formatted date */}
            </li>
          ))}
        </ul>
      </div>
    </Draggable>
  );
};

export default TFollowers;
