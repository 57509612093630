import React from 'react';
import { ClerkProvider } from '@clerk/clerk-react';
import Login from './Login';


if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
    throw new Error("Missing Publishable Key")
}

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;


const Start = () => {
    return(
        <React.StrictMode>
            <ClerkProvider publishableKey={clerkPubKey}>
                <Login />
            </ClerkProvider>
        </React.StrictMode>
    );
}

export default Start;
