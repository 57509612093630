// DraggableItem.js

import React from 'react';
import Draggable from 'react-draggable';
import './DraggableItem.css';

const DraggableItem = ({ title, gridX, gridY, bounds }) => {
  return (
    <Draggable grid={[gridX, gridY]} bounds={bounds}>
      <div className="draggable-item">{title}</div>
    </Draggable>
  );
};

export default DraggableItem;
