import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import './TwitchFollowers.css';

const TwitchFollowers = () => {
  const [followerCount, setFollowerCount] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getClerkUserIdFromLocalStorage = () => {
      const clerkUserId = localStorage.getItem('clerkUserId');
      return clerkUserId;
    };

    const clerkUserId = getClerkUserIdFromLocalStorage();
    if (!clerkUserId) {
      setError('Clerk UserID not found in Local Storage');
      return;
    }

    fetchData(clerkUserId); // Pass the clerkUserId to fetchData
  }, []);

  const fetchData = async (clerkUserId) => {
    try {
      const response = await fetch('https://api.channalytics.live/api/twitch/followers', {
        method: 'GET', // Assuming it's a GET request
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'clerkUserId': clerkUserId, // Pass clerkUserId directly in the header
        },
      });
      if (!response.ok) {
        throw new Error('Loading...');
      }
      const jsonData = await response.json();
      console.log("API Response:", jsonData); // Log the entire response
      if (jsonData && jsonData.data) {
        const totalFollowers = jsonData.data.length; // Get total number of followers
        setFollowerCount(totalFollowers); // Update state with total follower count
      } else {
        throw new Error('Invalid API response format or empty data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error);
    }
  };
  

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="Twitch-Followers">
      <div className="header-Twitch-Followers">Twitch Followers</div> {/* Display "Twitch Followers" at the top */}
      <div className="profile-icon">
        <FontAwesomeIcon icon={faUsers} color="#e06805" size="2x" /> {/* Orange profile icon */}
        <span className="twitch-follower-count">{followerCount}</span> {/* Display follower count */}
      </div>
    </div>
  );
};

export default TwitchFollowers;
