import React, { useState } from 'react';
import GobblerGrabber from './components/Builder/GobblerGrabber';
import './TWMods.css';

const TWMods = () => {
  const [mods, setMods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  // Callback to receive data from GobblerGrabber
  const handleDataReceived = (data) => {
    if (data && data.getTwModerationDetails && data.getTwModerationDetails.channelModerators) {
      // Flatten the array to extract moderators
      const flattenedMods = data.getTwModerationDetails.channelModerators.map(mod => mod.moderators);
      setMods(flattenedMods);
      setLoading(false);
    }
  };

  // Function to handle search query changes
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filtered moderators based on search query
  const filteredMods = mods.filter((mod) =>
    mod.user_login.toLowerCase().startsWith(searchQuery.toLowerCase())
  );

  return (
    <div className="mods-container">
      <h1>Twitch Moderators</h1>
      <input
        type="text"
        placeholder="Search moderators..."
        value={searchQuery}
        onChange={handleSearchChange}
        className="mods-search-bar"
      />
      <GobblerGrabber onDataReceived={handleDataReceived} />

      {loading ? (
        <p className="mods-loading"></p>
      ) : (
        <ul className="mods-list">
          {filteredMods.map((mod, index) => (
            <li key={index} className="mods-list-item">
              <strong>{mod.user_login}</strong>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TWMods;
